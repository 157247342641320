import React from "react";
import {
	BlueSquares1,
	BlueSquares2,
	PinkSquares0,
	PinkSquares1,
	PinkCircles0,
	PinkCircles1,
} from "@assets/Background";
import { PageLayout, TrustedBy } from "@components";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import { GetADemo } from "@components/GetADemo/GetADemo";
import { StaticImage } from "gatsby-plugin-image";
import { pinkText } from "@components/Typography/Typography";
import ImageAndBulletPoints from "@components/ImageAndBulletPoints/ImageAndBulletPoints";
import { SectionTitle } from "@components/SectionTitle/SectionTitle";
import LearnMoreSection from "@components/LearnMoreSection/LearnMoreSection";
import * as commonStyles from "../../CommonResourcesOverview.module.scss";

export default function Managers() {
	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "lab-manager" }}
		>
			<Demo />
			<ELN />
			<SetupForSuccess />
			<Enterprise />
			<TrustedBy />
			<LearnMoreSection textColor="text-dark" background="pinkFade" />
		</PageLayout>
	);
}

const Demo = () => (
	<BackgroundFadeContainer type="blueFade" skewBottom withHeaderPadding>
		<BackgroundShapes
			leftShape={{
				shape: <BlueSquares1 />,
				xOffset: -140,
				yOffset: 120,
			}}
			rightShape={{ shape: <BlueSquares2 />, xOffset: 140 }}
		>
			<GetADemo
				titleText="Create and innovate faster"
				subtitleText="Standardize and scale processes company wide, whether your team is collocated or distributed worldwide."
				linkColor="ui_01"
				titleWidth={450}
				subtitleWidth={550}
				imageNode={
					<StaticImage
						loading="eager"
						src={
							"../../../assets/products/project-metrics-snippet.png"
						}
						alt="project metrics example"
					/>
				}
			/>
		</BackgroundShapes>
	</BackgroundFadeContainer>
);

const ELN = () => (
	<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
		<BackgroundShapes
			leftShape={{
				shape: <PinkSquares0 />,
				xOffset: -140,
				yOffset: 10,
			}}
			rightShape={{ shape: <PinkSquares1 />, xOffset: 170, yOffset: 180 }}
		>
			<SectionTitle
				title={pinkText`Where project management meets electronic lab notebook`}
				subtitle="Group similar research experiments in subprojects and see how Genemod helps you optimize your time and resources."
				textColor="text-dark"
				titleWidth={832}
				subtitleWidth={658}
				marginTop={140}
			/>
			<StaticImage
				loading="eager"
				className={commonStyles.fullWidthImage}
				src="../../../assets/products/subproject_large.png"
				alt="example screenshot of project organization webpage"
			/>
		</BackgroundShapes>
	</BackgroundFadeContainer>
);

const SetupForSuccess = () => (
	<BackgroundFadeContainer type="blueFade" skewTop skewBottom>
		<SectionTitle
			title={pinkText`Set your team up for ${"success"} from day one`}
			subtitle="We bring your team’s work together in one shared space. Improve process management by leaving the repetitive stuff to us so you can do more of the work you do best."
			textColor="ui-01"
			titleWidth={962}
			subtitleWidth={936}
			marginBottom={30}
			marginBottomTablet={60}
		/>
		<ImageAndBulletPoints
			width={966}
			bullets={[
				{
					title: pinkText`How does ${"“Powered by Genemod”"} work?`,
					text: "Genemod enables institutions with multiple teams to centralize projects and experiments on one platform. Google Docs isn’t built for life science R&D - so we built a platform that is.",
				},
				{
					title: "Built for 21 CFR Part 11 compliance",
					text: "Easily manage version history without the need to save multiple protocol copies.",
				},
			]}
			image={
				<StaticImage
					loading="eager"
					src="../../../assets/products/ROI-graph.png"
					alt="3rd party, heap estimate of savings by using genemod"
					width={441}
					height={477}
				/>
			}
			bulletStyles={{
				titleVariant: "HEADER4",
				titleColor: "ui-01",
				textVariant: "SUBHEADERDESC",
				textColor: "ui-01",
				containerWidth: 476,
			}}
		/>
	</BackgroundFadeContainer>
);

const Enterprise = () => (
	<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
		<BackgroundShapes
			leftShape={{
				shape: <PinkCircles0 />,
				xOffset: -140,
				yOffset: 10,
			}}
			rightShape={{ shape: <PinkCircles1 />, xOffset: 170, yOffset: 180 }}
		>
			<SectionTitle
				title={pinkText`Manage multiple research teams on ${"Genemod Enterprise"}`}
				subtitle="Org Owners and Org Admins can decide who can manage multi-workspace research teams within an Enterprise Grid organization."
				textColor="text-dark"
				titleWidth={760}
				subtitleWidth={790}
				marginBottom={12}
				marginBottomTablet={12}
				marginBottomMobile={12}
			/>
			<StaticImage
				loading="eager"
				className={commonStyles.fullWidthImage}
				src="../../../assets/products/switch-workspace-large.png"
				alt="example of multiple workspaces"
			/>
		</BackgroundShapes>
	</BackgroundFadeContainer>
);
